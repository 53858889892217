import { 
    confirmDeleteBook, 
    confirmDeleteShelf,
    confirmDeleteList,
    confirmRemoveFromList,
    confirmReturnBook,
    confirmCancelBorrowRequest,
    confirmSendBorrowRequest,
    confirmRequestFollowUser,
    confirmDeleteGroup,
    confirmLeaveGroup,
    confirmRemoveMemberFromGroup,
    profileMustBeFilledToRequestBook,
    promptLoginOrSignup,
    confirmDeleteGroupCollection,
    confirmRequestReturnBook
} from './alerts';
import { 
    MaterialCommunityIcons as MCIcons, 
    MaterialIcons as MIcons, 
    Ionicons 
} from '@expo/vector-icons';
import { IcoMoon } from './customIcons';
import { isNative, isWeb } from '.';
import { BOOK_STATUS_LISTS } from '../constants';

function missingActionFn() {
    __DEV__ && console.log('Missing action function');
}

export function menuUser({ 
        isFollowing, 
        isFollowed, 
        isYou, 
        isPrivate, 
        isLoggedIn, 
        allowContactUser=false,
        isAllowedMultipleAdmins,
        isUserGroupAdmin,
        isLoggedUserGroupAdmin, 
        hasRequested, 
        context, 
        dispatch, 
        nickname, 
        actions,
        t }) {
    const items = [];
    const canUnfollow = isFollowing && !isYou;
    const canFollow = !isFollowing && !isYou && !hasRequested && !isPrivate;
    const canEditProfile = isYou;
    const canViewMyProfile = isYou;
    const canViewUserProfile = !isYou && context != 'detail';
    const canCancelRequest = hasRequested;
    const canRequestFollowUser = !isFollowing && isPrivate && !hasRequested && !isYou;
    const canContactUser = (!isYou && isFollowed && isFollowing) || allowContactUser;
    const canAddAsAdmin = isLoggedUserGroupAdmin && (isAllowedMultipleAdmins || isWeb()) && !isUserGroupAdmin && context == 'groupMembers' && !isYou;
    const canRemoveAdmin = isLoggedUserGroupAdmin && isAllowedMultipleAdmins && isUserGroupAdmin && context == 'groupMembers' && !isYou;
    const canRemoveMemberFromGroup = isLoggedUserGroupAdmin && context == 'groupMembers' && !isYou && !canRemoveAdmin;
    canFollow && items.push({
        label: t(`menus.user.follow`),
        iconName: 'person-add',
        Icon: Ionicons,
        onSelect: () => isLoggedIn ? actions.onFollow() : promptLoginOrSignup(dispatch)
    })
    canRequestFollowUser && items.push({
        label: t(`menus.user.requestFollow`),
        iconName: 'person-add',
        Icon: Ionicons,
        onSelect: () => confirmRequestFollowUser(actions.onRequestFollow || missingActionFn, dispatch, { nickname }) 
    })
    canContactUser && items.push({
        label: t(`menus.user.contactUser`),
        iconName: 'mail',
        Icon: Ionicons,
        onSelect: actions.onContactUser || missingActionFn
    })
    canEditProfile && items.push({
        label: t(`menus.user.editYourProfile`),
        iconName: 'edit',
        Icon: MIcons,
        onSelect: actions.onEditMyProfile || missingActionFn
    })
    canCancelRequest && items.push({
        label: t(`menus.user.cancelFollowRequest`),
        iconName: 'remove-circle',
        Icon: MIcons,
        onSelect: actions.onCancelFollowRequest || missingActionFn
    })
    canViewUserProfile && items.push({
        label: t(`menus.user.viewUserProfile`),
        iconName: 'person',
        Icon: Ionicons,
        onSelect: actions.onViewUserProfile || missingActionFn
    })
    canViewMyProfile && items.push({
        label: t(`menus.user.viewYourProfile`),
        iconName: 'person',
        Icon: Ionicons,
        onSelect: actions.onViewMyProfile || missingActionFn
    })
    canAddAsAdmin && items.push({
        label: t(`menus.user.addAdmin`),
        iconName: 'account-star',
        Icon: MCIcons,
        showPremiumOnly: !isAllowedMultipleAdmins,
        onSelect: actions.onAdminAdd || missingActionFn
    })
    canRemoveAdmin && items.push({
        label: t(`menus.user.removeAdmin`),
        iconName: 'md-remove-circle',
        destructive: true,
        Icon: Ionicons,
        onSelect: actions.onAdminRemove || missingActionFn
    })
    canUnfollow && items.push({
        label: t(`menus.user.unfollow`),
        iconName: 'person-remove',
        destructive: true,
        Icon: Ionicons,
        onSelect: actions.onUnfollow || missingActionFn
    })
    canRemoveMemberFromGroup && items.push({
        label: t(`menus.user.removeFromGroup`),
        iconName: 'md-remove-circle',
        destructive: true,
        Icon: Ionicons,
        onSelect: () => confirmRemoveMemberFromGroup(actions.onRemoveFromGroup || missingActionFn, dispatch, { nickname })
    }) 
    return items;
}

export function bookMenu({ dispatch, isOwner, isLent, isPossessed, alreadyRequested, isLoggedIn, canBorrowBook, isProfileComplet, owner, book, list, groupId='', actions={}, t }) {
    const items = [];
    const canDelete = isOwner && !list && !groupId;
    const canLend = isOwner && !isLent && isPossessed;
    const canReturn = isOwner && isLent;
    const canChangeVisibilty = isOwner && !groupId;
    const canRemoveFromList = isOwner && list && !BOOK_STATUS_LISTS.includes(list.type);
    const canCancelBorrowRequest = alreadyRequested;
    const canAddRemoveFromList = !isOwner;
    const canRemoveBookFromGroup = isOwner && groupId;
    const handleBorrowBookPress = () => {
        if(!isLoggedIn) {
            promptLoginOrSignup(dispatch);
        } else if(isProfileComplet) {
            confirmSendBorrowRequest(actions.onBorrowRequest || missingActionFn, dispatch, { user: owner, book })
        } else {
            profileMustBeFilledToRequestBook(actions.onFillProfileToBorrow || missingActionFn, dispatch)
        }
    }
    isOwner && items.push({
        label: t(`menus.book.modify`),
        iconName: 'edit',
        Icon: MIcons,
        onSelect: actions.onUpdate || missingActionFn
    })
    canLend && items.push({
        label: t(`menus.book.lend`),
        iconName: 'upload',
        Icon: IcoMoon,
        onSelect: actions.onLend || missingActionFn
    })
    canBorrowBook && items.push( {
        label: t(`menus.book.borrow`),
        iconName: 'upload',
        Icon: IcoMoon,
        onSelect: handleBorrowBookPress
    })
    canReturn && items.push({
        label: t(`menus.book.return`),
        iconName: 'download',
        Icon: IcoMoon,
        onSelect: () => confirmReturnBook(actions.onReturn || missingActionFn, dispatch)
    })
    canCancelBorrowRequest && items.push({
        label: t(`menus.book.cancelBorrowRequest`),
        iconName: 'remove-circle',
        Icon: MIcons,
        onSelect: () => confirmCancelBorrowRequest(actions.onCancelBorrowRequest || missingActionFn, dispatch)
    })
    canAddRemoveFromList && items.push({
        label: t(`menus.book.addRemoveFromList`),
        iconName: 'add-circle',
        Icon: MIcons,
        onSelect: () => isLoggedIn ? actions.onAddRemoveFromList() : promptLoginOrSignup(dispatch)
    })
    canChangeVisibilty && items.push({
        label: book.hidden ? t(`menus.book.show`) : t(`menus.book.hide`),
        iconName: book.hidden ? 'eye-outline' : 'eye-off-outline',
        Icon: MCIcons,
        onSelect: actions.onSetHidden || missingActionFn
    })
    canRemoveFromList && items.push({
        label: t(`menus.book.removeFromList`),
        iconName: 'remove-circle',
        destructive: true,
        Icon: MIcons,
        onSelect: () => confirmRemoveFromList(actions.onRemoveFromList || missingActionFn, dispatch)
    })
    canRemoveBookFromGroup && items.push({
        label: t(`menus.book.removeFromGroup`),
        iconName: 'remove-circle',
        destructive: true,
        Icon: MIcons,
        onSelect: actions.onRemoveFromGroup || missingActionFn
    }) 
    canDelete && items.push({
        label: t(`menus.book.delete`),
        iconName: 'delete',
        destructive: true,
        Icon: MCIcons,
        onSelect: () => confirmDeleteBook(actions.onDelete || missingActionFn, dispatch)
    })
    return items;
}

export function bookLendMenu({ dispatch, context, lend, user, book, shouldShowContact, actions={}, t }) {
    const items = [];
    const canContactUser = user && (context == 'lending' || context == 'borrowing' || context.indexOf('borrowRequest') > -1) && shouldShowContact;
    const canRequestReturnBook = user && context == 'lending' && lend && lend.returnRequestedDatetime === null;
    const canVieUser = !!user;
    canRequestReturnBook && items.push({
        label: t(`menus.bookLend.requestReturn`),
        iconName: 'download',
        Icon: IcoMoon,
        onSelect: () => confirmRequestReturnBook(actions.onRequestReturn || missingActionFn, dispatch, { nickname: user.nickname, bookTitle: book.title })
    })
    canContactUser && items.push({
        label: t(`menus.bookLend.contact`),
        iconName: 'email',
        Icon: MIcons,
        onSelect: actions.onContactUser || missingActionFn
    })
    items.push({
        label: t(`menus.bookLend.viewBook`),
        iconName: 'book',
        Icon: MIcons,
        onSelect: actions.onViewBook || missingActionFn
    })
    canVieUser && items.push({
        label: t(`menus.bookLend.viewUser`),
        iconName: 'person',
        Icon: Ionicons,
        onSelect: actions.onViewUser || missingActionFn
    })
    return items;
}

export function shelfMenu({ dispatch, actions={}, t }) {
    return [
        {
            label: t(`menus.shelf.modify`),
            iconName: 'edit',
            Icon: MIcons,
            onSelect: actions.onUpdate || missingActionFn
        },
        {
            label: t(`menus.shelf.delete`),
            iconName: 'delete',
            destructive: true,
            Icon: MCIcons,
            onSelect: () => confirmDeleteShelf(actions.onDelete || missingActionFn, dispatch)
        }
    ]
}

export function listMenu({ dispatch, actions={}, deletable=true, modifiable=true, t }) {
    const items = [];
    if(modifiable) items.push({
        label: t(`menus.list.modify`),
        iconName: 'edit',
        Icon: MIcons,
        onSelect: actions.onUpdate || missingActionFn
    })
    if(deletable) items.push({
        label: t(`menus.list.delete`),
        iconName: 'delete',
        destructive: true,
        Icon: MCIcons,
        onSelect: () => confirmDeleteList(actions.onDelete || missingActionFn, dispatch)
    })
    return items;
}

export function groupCollectionMenu({ dispatch, name, actions={}, t }) {
    const items = [];
    items.push({
        label: t(`menus.groupCollection.modify`),
        iconName: 'edit',
        Icon: MIcons,
        onSelect:  actions.onUpdate || missingActionFn
    })
    items.push({
        label: t(`menus.groupCollection.addRemoveBooks`),
        iconName: 'add-circle',
        Icon: MIcons,
        onSelect:  actions.onAddRemoveBooks || missingActionFn
    })
    items.push({
        label: t(`menus.groupCollection.delete`),
        iconName: 'delete',
        destructive: true,
        Icon: MCIcons,
        onSelect: () => confirmDeleteGroupCollection(actions.onDelete || missingActionFn, dispatch, { name })
    })
    return items;
}
 

export function groupMenu({ dispatch, isAdmin, isMember, name, hasRequestedJoin, actions={}, t }) {
    const items = [];
    const canDelete = isAdmin;
    const canUpdate = isAdmin;
    const canAddOrRemoveBooks = isMember;
    const canInviteToJoin = isMember;
    const canLeaveGroup = isMember && !isAdmin;
    const canCancelJoinRequest = hasRequestedJoin;
    const canOpenShareOptions = isAdmin;
    canAddOrRemoveBooks && items.push({
        label: t(`menus.group.addRemoveBooks`),
        iconName: 'add-circle',
        Icon: MIcons,
        onSelect:  actions.onAddRemoveBooks || missingActionFn
    })
    canInviteToJoin && items.push({
        label: t(`menus.group.inviteToJoin`),
        iconName: 'person-add',
        Icon: Ionicons,
        onSelect:  actions.onInviteToJoin || missingActionFn
    })
    canOpenShareOptions && items.push({
        label: t(`menus.group.share`),
        iconName: 'share',
        Icon: MIcons,
        onSelect: actions.onGroupShare || missingActionFn
    })
    canUpdate && items.push({
        label: t(`menus.group.modify`),
        iconName: 'edit',
        Icon: MIcons,
        onSelect: actions.onUpdate || missingActionFn
    })
    canLeaveGroup && items.push({
        label: t(`menus.group.leave`),
        iconName: 'md-remove-circle',
        destructive: true,
        Icon: Ionicons,
        onSelect: () => confirmLeaveGroup(actions.onLeave || missingActionFn, dispatch, { name })
    })
    canDelete && items.push({
        label: t(`menus.group.delete`),
        iconName: 'delete',
        destructive: true,
        Icon: MCIcons,
        onSelect: () => confirmDeleteGroup(actions.onDelete || missingActionFn, dispatch, { name })
    })
    canCancelJoinRequest && items.push({
        label: t(`menus.group.cancelJoinRequest`),
        iconName: 'remove-circle',
        destructive: true,
        Icon: MIcons,
        onSelect: actions.onCancelJoinRequest || missingActionFn
    })
    return items;
}

export function bookCoverMenu({ actions={}, isbn, t }) {
    let items = [
        {
            label: t(`menus.bookCover.chooseImg`),
            iconName: 'image',
            Icon: MIcons,
            onSelect: actions.onPickImage || missingActionFn
        }
    ]
    isNative() && items.push({
        label: t(`menus.bookCover.takePhoto`),
        iconName: 'camera',
        Icon: MCIcons,
        onSelect: actions.onTakePhoto || missingActionFn
    })
    isbn && items.push({
        label: t(`menus.bookCover.generate`),
        iconName: 'color-wand',
        Icon: Ionicons,
        onSelect: actions.onGenerate || missingActionFn
    })
    return items;
}

export function addMenu({ actions={}, t }) {
    return [
        {
            label: t(`menus.add.book`),
            iconName: 'book',
            Icon: MIcons,
            onSelect: actions.onAddBook || missingActionFn
        },
        {
            label: t(`menus.add.shelf`),
            iconName: 'library',
            Icon: Ionicons,
            onSelect: actions.onAddShelf || missingActionFn
        },
        {
            label: t(`menus.add.list`),
            iconName: 'list',
            Icon: Ionicons,
            onSelect: actions.onAddList || missingActionFn
        }
    ]
}

export function shareWebMenu({ actions={}, t }) {
    return [
        {
            label: t(`menus.shareWeb.facebook`),
            iconName: 'logo-facebook',
            Icon: Ionicons,
            onSelect: actions.onShareFacebook || missingActionFn
        },
        {
            label: t(`menus.shareWeb.twitter`),
            iconName: 'logo-twitter',
            Icon: Ionicons,
            onSelect: actions.onShareTwitter || missingActionFn
        },
        {
            label: t(`menus.shareWeb.email`),
            iconName: 'md-mail',
            Icon: Ionicons,
            onSelect: actions.onShareEmail || missingActionFn
        }
    ]
}

export function groupShareBooksOfShelfSettingsMenu({ actions={}, values, t }) {
    return [
        {
            title: t(`menus.shareBooksOfShelfSettings.autoAddBooksOfShelf.title`),
            description: t(`menus.shareBooksOfShelfSettings.autoAddBooksOfShelf.description`),
            onUpdateSetting: actions.onUpdateautoAddBooksOfShelf || missingActionFn,
            initialValue: values.shouldAutoAdd
        }
    ]
}