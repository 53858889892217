import * as FileSystem from 'expo-file-system'

// Domain
export const DOMAIN = (() => {
    if(__DEV__) { return 'http://192.168.1.52:5000'; }
    return 'https://mybl.io';
})();

const API = DOMAIN + '/api';

// API Urls
export const ADD_SHELF_API_URL                              = API + '/shelf/create';
export const DELETE_SHELF_API_URL                           = API + '/shelf/delete';
export const UPDATE_SHELF_API_URL                           = API + '/shelf/update';
export const GET_SHELF_API_URL                              = API + '/shelf/get';

export const GET_SHELVES_API_URL                            = API + '/shelves/get';

export const ADD_LIST_API_URL                               = API + '/list/create';
export const UPDATE_LIST_API_URL                            = API + '/list/update';
export const DELETE_LIST_API_URL                            = API + '/list/delete';
export const GET_LIST_API_URL                               = API + '/list/get';

export const GET_LISTS_API_URL                              = API + '/lists/get';

export const SEARCH_BOOK_PROVIDERS_API_URL                  = API + '/books/providers/search';
export const GET_USER_BOOK_LIKES_API_URL                    = API + '/books/likes/get';

export const UPDATE_BOOK_API_URL                            = API + '/book/update';
export const ADD_BOOK_API_URL                               = API + '/book/create';
export const DELETE_BOOK_API_URL                            = API + '/book/delete';
export const GENERATE_COVER_API_URL                         = API + '/book/cover/get'
export const SET_BOOK_HIDDEN_API_URL                        = API + '/book/set/hidden';
export const SET_BOOK_LIKE_API_URL                          = API + '/book/set/like';
export const GET_BOOK_LIKES_API_URL                         = API + '/book/likes/get';
export const REMOVE_BOOK_FROM_LIST_API_URL                  = API + '/book/list/remove';
export const UPDATE_BOOK_IN_LISTS_API_URL                   = API + '/book/lists/update';
export const LEND_BOOK_API_URL                              = API + '/book/lend';
export const REQUEST_RETURN_BOOK_API_URL                    = API + '/book/lend/request/return';
export const RETURN_BOOK_API_URL                            = API + '/book/return';
export const REQUEST_BORROW_BOOK_API_URL                    = API + '/book/borrow/request/send';
export const ACCEPT_BORROW_REQUEST_API_URL                  = API + '/book/borrow/request/accept';
export const REFUSE_BORROW_REQUEST_API_URL                  = API + '/book/borrow/request/refuse';
export const CANCEL_BORROW_REQUEST_API_URL                  = API + '/book/borrow/request/cancel';
export const FINALISE_BORROW_REQUEST_API_URL                = API + '/book/borrow/request/finalise';
export const GET_BORROW_REQUESTS_API_URL                    = API + '/book/borrow/requests/get';
export const REMOVE_BOOK_FROM_GROUP_API_URL                 = API + '/book/group/remove';
export const GET_BOOK_OWNERS_API_URL                        = API + '/book/owners/get';
export const ADD_BOOK_REVIEW_API_URL                        = API + '/book/review/create';
export const UPDATE_BOOK_REVIEW_API_URL                     = API + '/book/review/update';
export const DELETE_BOOK_REVIEW_API_URL                     = API + '/book/review/delete';
export const GET_BOOK_REVIEWS_API_URL                       = API + '/book/reviews/get';
export const GET_BOOK_API_URL 								= API + '/book/get';

export const GET_USER_API_URL                               = API + '/user/get';
export const FOLLOW_USER_API_URL                            = API + '/user/follow';
export const UNFOLLOW_USER_API_URL                          = API + '/user/unfollow';
export const REQUEST_FOLLOW_USER_API_URL                    = API + '/user/follow/request/send';
export const CANCEL_FOLLOW_USER_REQUEST_API_URL             = API + '/user/follow/request/cancel';
export const REFUSE_FOLLOW_USER_REQUEST_API_URL             = API + '/user/follow/request/refuse';
export const ACCEPT_FOLLOW_USER_REQUEST_API_URL             = API + '/user/follow/request/accept';
export const GET_FOLLOW_USER_REQUESTS_API_URL               = API + '/user/follow/requests/get';

export const GET_USERS_API_URL                              = API + '/users/get';

export const GET_LENDS_API_URL                              = API + '/lends/get';

export const ADD_GROUP_API_URL                              = API + '/group/create';
export const UPDATE_GROUP_API_URL                           = API + '/group/update';
export const DELETE_GROUP_API_URL                           = API + '/group/delete';
export const UPDATE_BOOKS_IN_GROUP_API_URL                  = API + '/group/books/update';
export const SEND_GROUP_INVITATION_API_URL                  = API + '/group/invitation/send';
export const ACCEPT_GROUP_INVITATION_API_URL                = API + '/group/invitation/accept';
export const REFUSE_GROUP_INVITATION_API_URL                = API + '/group/invitation/refuse';
export const SEND_GROUP_INVITATIONS_API_URL                 = API + '/group/invitation/send-multiple';
export const SEND_GROUP_REQUEST_API_URL                     = API + '/group/request/send';
export const ACCEPT_GROUP_REQUEST_API_URL                   = API + '/group/request/accept';
export const REFUSE_GROUP_REQUEST_API_URL                   = API + '/group/request/refuse';
export const CANCEL_GROUP_REQUEST_API_URL                   = API + '/group/request/cancel';
export const LEAVE_GROUP_API_URL                            = API + '/group/member/leave';
export const REMOVE_GROUP_MEMBER_API_URL                    = API + '/group/member/remove';
export const GET_GROUP_REQUESTS_API_URL                     = API + '/group/requests/get';
export const GET_GROUP_INVITATIONS_API_URL                  = API + '/group/invitations/get';
export const GET_GROUP_API_URL                              = API + '/group/get';
export const ADD_ADMIN_API_URL                              = API + '/group/admin/add';
export const REMOVE_ADMIN_API_URL                           = API + '/group/admin/remove';
export const GET_GROUP_COLLECTION_API_URL                   = API + '/group/collection/get';
export const ADD_GROUP_COLLECTION_API_URL                   = API + '/group/collection/create';
export const DELETE_GROUP_COLLECTION_API_URL                = API + '/group/collection/delete';
export const UPDATE_GROUP_COLLECTION_API_URL                = API + '/group/collection/update';
export const UPDATE_BOOKS_IN_GROUP_COLLECTION_API_URL       = API + '/group/collection/books/update';
export const GET_GROUP_PRODUCTS_API_URL                     = API + '/group/products/get';
export const GET_GROUP_SUBSCRIPTION_API_URL                 = API + '/group/subscription/get';
export const GENERATE_GROUP_POSTER_API_URL                  = API + '/group/poster/generate';
export const DOWNLOAD_GROUP_POSTER_API_URL                  = API + '/group/poster/download';

export const SET_GROUP_COLLECTIONS_ORDER_API_URL            = API + '/group/collections/set/order';

export const GET_MY_GROUPS_API_URL                          = API + '/groups/get';
export const GET_PUBLIC_GROUPS_API_URL                      = API + '/groups/public/get';

export const SEARCH_API_URL                                 = API + '/search';
export const GET_ACTIVITY_API_URL                           = API + '/activity';

export const SIGNUP_API_URL                                 = API + '/account/signup';
export const LOGIN_API_URL                                  = API + '/account/login';
export const LOGOUT_API_URL                                 = API + '/account/logout';
export const UPDATE_ACCOUNT_API_URL                         = API + '/account/update';
export const RESET_PASSWORD_API_URL                         = API + '/account/reset/password';
export const CHANGE_PASSWORD_API_URL                        = API + '/account/change/password';
export const SET_NEWSLETTER_API_URL                         = API + '/account/set/receive-newsletter';
export const SET_NOTIFS_BY_EMAIL_API_URL                    = API + '/account/set/receive-notifs-by-email';
export const SET_MESSAGES_BY_EMAIL_API_URL                  = API + '/account/set/receive-messages-by-email';
export const SET_LEND_BOOK_DURATION_IN_DAYS_API_URL         = API + '/account/set/lend-book-duration-in-days';
export const SET_PRIVATE_API_URL                            = API + '/account/set/private';
export const SET_EXPO_TOKEN_API_URL                         = API + '/account/set/expo-token';
export const SET_FOUND_IN_SEARCH_API_URL                    = API + '/account/set/found-in-search';
export const SET_SORT_LIBRARY_BOOKS_API_URL                 = API + '/account/set/sort-library-books';
export const SET_PREFERRED_LANG_API_URL                     = API + '/account/set/preferred-lang';
export const DOWNLOAD_ACCOUNT_DATA_URL                      = API + '/account/download/data';
export const DELETE_ACCOUNT_API_URL                         = API + '/account/delete';
export const GET_GROUP_SUBSCRIPTIONS_API_URL                = API + '/account/subscriptions/group/get';
export const GET_UNREAD_NOTIFS_API_URL                      = API + '/account/notifs/unread/get';
export const UPDATE_UNREAD_NOTIFS_API_URL                   = API + '/account/notifs/unread/update';
export const SET_HAS_SEEN_API_URL                           = API + '/account/has-seen';
export const SEND_FEEDBACK_API_URL                          = API + '/account/feedback/send';
export const GET_ACCOUNT_CONTACTS_STATUS_API_URL            = API + '/account/contacts/check';
export const ADD_USER_KEYWORD_API_URL                       = API + '/account/keyword/create';
export const UPDATE_USER_KEYWORD_API_URL                    = API + '/account/keyword/update';
export const DELETE_USER_KEYWORD_API_URL                    = API + '/account/keyword/delete';
export const GET_ACCOUNT_PRODUCTS_API_URL                   = API + '/account/products/get';
export const CREATE_ACCOUNT_CHECKOUT_SESSION_API_URL        = API + '/account/products/create-checkout-session';
export const GET_ACCOUNT_SUBSCRIPTION_API_URL               = API + '/account/subscription/get';

export const CREATE_DISCUSSION_API_URL                      = API + '/discussion/create';
export const SEND_MESSAGE_API_URL                           = API + '/discussion/message/send';
export const SET_READ_MESSAGES_API_URL                      = API + '/discussion/messages/read';
export const GET_DISCUSSIONS_API_URL                        = API + '/discussions/get';

export const GET_KEYWORDS_API_URL                           = API + '/keywords/get';

export const SEND_USER_INVITATIONS_API_URL                  = API + '/invite/users';

export const LOG_EVENT_API_URL                              = API + '/events/log';

export const CREATE_CHECKOUT_SESSION_API_URL                = API + '/group/products/create-checkout-session';

export const APP_ROOT_PATH = 'app/';

// Other Urls
export const MENTIONS   = DOMAIN + '/mentions?affichage=simple'
export const PRIVACY    = DOMAIN + '/politique-de-confidentialite?affichage=simple'

// email
export const CONTACT_US_EMAIL = 'contact@mybl.io'

// Colors
export const MAIN_COLOR             = '#EE6666';
export const MAIN_COLOR_DARK        = '#BA5252';
export const MAIN_COLOR_LIGHT       = '#FF7171';
export const MAIN_COLOR_SUBTLE      = '#fef4f5';

export const SECONDARY_COLOR        = '#2B4570';
export const SECONDARY_COLOR_DARK   = '#223A56';
export const SECONDARY_COLOR_LIGHT  = '#3F669E';
export const SECONDARY_COLOR_SUBTLE = '#ebeef2';

export const TERTIARY_COLOR         = '#E4B363';
export const TERTIARY_COLOR_DARK    = '#C19457';
export const TERTIARY_COLOR_LIGHT   = '#FFCC83';

export const OFF_WHITE              = '#fdfdfc';
export const OFF_BLACK              = '#191919';
export const PLACEHOLDER_TEXT_COLOR = '#707D84';
export const SUCCESS_COLOR          = '#2BC016';
export const DANGER_COLOR           = '#BF2932';
export const GRAY_LIGHT             = '#f4f5f8';
export const GRAY_TEXT_PALE         = '#94989D';
export const GRAY_TEXT_DIM          = '#606567'; // #666 tone
export const GRAY_LINE_COLOR 		= '#d7dbe4';

export const TWITTER_COLOR			= '#55acee';
export const FACEBOOK_COLOR 		= '#3b5999';
export const INSTAGRAM_COLOR 		= '#517fa4';
export const MASTODON_COLOR			= '#2b90d9';
export const YOUTUBE_COLOR 			= '#FF0000';

// Measures
export const BOOK_COVER_RATIO                   = 1.5; // previously 1.4289
export const HEADER_ACTIONS_HEIGHT              = 34;
export const HEADER_ACTIONS_PADDING_HOR_COMPACT = 12;
export const HEADER_ACTIONS_PADDING_HOR         = 20;
export const USER_HEADER_HEIGHT                 = 170;
export const USER_HEADER_HEIGHT_MIN             = 49;
export const GROUP_HEADER_HEIGHT_MIN            = 49;
export const LARGE_SCREEN_THRESHOLD             = 700;
export const DRAWER_WIDTH                       = 230;
export const TAB_HEIGHT                         = 50;
export const MODAL_MAX_WIDTH                    = 600; 
export const MODAL_MAX_HEIGHT                   = 700;      
export const BOOK_LIST_ITEM_BOTTOM_SPACING      = 25;
export const RATIO_BOOK_LIST_ITEM               = 1.7382352941;    

// Reading status

export const READING_STATUSES = ['unread', 'toRead', 'reading', 'read'];

// Lists

export const BOOK_STATUS_LISTS = ['reading', 'toRead', 'read', 'unread', 'possessed'];
export const DEFAULT_LISTS = ['wish', ...BOOK_STATUS_LISTS];

export const LIST_RULES = {
    "wish": {
        canAddBooks: true,
        canModify: false,
        canContainPossessedBooks: false,
    },
    "user": {
        canAddBooks: true,
        canModify: true,
        canContainPossessedBooks: true
    },
    "possessed": {
        canAddBooks: false,
        canModify: true,
        canContainPossessedBooks: true
    },
    "unread": {
        canAddBooks: false,
        canModify: true,
        canContainPossessedBooks: true
    },
    "reading": {
        canAddBooks: false,
        canModify: true,
        canContainPossessedBooks: true
    },
    "read": {
        canAddBooks: false,
        canModify: true,
        canContainPossessedBooks: true
    },
    "toRead": {
        canAddBooks: false,
        canModify: true,
        canContainPossessedBooks: true
    }
}

// Other
export const ISO_DATE_FORMAT_1970               = '1970-01-01T00:00:00.000000'

export const PROFILE_DESCRIPTION_MAX_CHARS      = 180
export const PROFILE_NICKNAME_MAX_CHARS         = 50
export const PROFILE_LOCATION_MAX_CHARS         = 70
export const GROUP_NAME_MAX_CHARS               = 50
export const GROUP_DESCRIPTION_MAX_CHARS        = 180
export const GROUP_LOCATION_MAX_CHARS           = 70
export const GROUP_SLUG_MAX_CHARS               = 100
export const GROUP_COLLECTION_NAME_MAX_CHARS    = 50   
export const GROUP_COLLECTION_DESCRIPTION_MAX_CHARS = 180   
export const LIST_NAME_MAX_CHARS                = 50
export const LIST_DESCRIPTION_MAX_CHARS         = 300
export const SHELF_NAME_MAX_CHARS               = 50
export const CONTACT_USER_MAX_CHARS             = 500
export const ORGA_DESCRIPTION_MAX_CHARS 		= 500
export const ORGA_EMAIL_INVITATION_MAX_CHARS 	= 500
export const BOOK_REVIEW_MAX_CHARS 	            = 500
export const BOOK_REVIEW_MAX_TAGS               = 3 
export const GROUP_BOOK_LOCATION_MAX_CHARS      = 60
export const USER_KEYWORD_MAX_CHARS             = 25  
export const BOOK_CODE_MAX_CHARS                = 50   

export const BOOK_COVER_MAX_SIZE                = 500 // pixels

export const DEFAULT_BLUR_HASH                  = 'U4Sf00y@?_*z*Hn4S~Td+FZiD4qE%#WCo}sm';      

export const SHELF_VISIBILITY_OPTIONS = [
    { label: 'shelf.visibility.public',     value: 'public'     },
    { label: 'shelf.visibility.private',    value: 'private'    }
]

export const SHELF_VISIBILITY_PRIVATE_OPTIONS = [
    { label: 'shelf.visibility.followers',  value: 'followers'  }, 
    { label: 'shelf.visibility.private',    value: 'private'    }
]

export const LIST_VISIBILITY_OPTIONS = [
    { label: 'list.visibility.public',     value: 'public'      },
    { label: 'list.visibility.private',    value: 'private'     }
]

export const LIST_VISIBILITY_PRIVATE_OPTIONS = [
    { label: 'list.visibility.followers',  value: 'followers'   }, 
    { label: 'list.visibility.private',    value: 'private'     }
]

export const GROUP_USAGE_OPTIONS = [
    { label: 'group.usage.personal',    value: 'personal'   }, 
    { label: 'group.usage.business',    value: 'business'   },
    { label: 'group.usage.family',      value: 'family'     },
    { label: 'group.usage.education',   value: 'education'  },
    { label: 'group.usage.nonProfit',   value: 'nonProfit'  },
    { label: 'group.usage.religious',   value: 'religious'  },
    { label: 'group.usage.other',       value: 'other'      }
]

export const BOOK_ORDER_OPTIONS = [
    { label: 'book.sort.newest',      value: 'added_desc'  },
    { label: 'book.sort.oldest',      value: 'added_asc'   },
    { label: 'book.sort.titleAZ',     value: 'title_asc'   },
    { label: 'book.sort.titleZA',     value: 'title_desc'  },
    { label: 'book.sort.authorAZ',    value: 'author_asc'  },
    { label: 'book.sort.authorZA',    value: 'author_desc' },
    { label: 'book.sort.collectionAZ',    value: 'collection_asc' },
    { label: 'book.sort.collectionZA',    value: 'collection_desc' },
    { label: 'book.sort.publishedNewest',    value: 'published_desc' },
    { label: 'book.sort.publishedOldest',    value: 'published_asc' }
]

export const LEND_BOOK_DURATION_IN_DAYS = [
    { label: 'screens.settings.lendDurations.none', value: null },
    { label: 'screens.settings.lendDurations.oneWeek', value: 7 },
    { label: 'screens.settings.lendDurations.twoWeeks', value: 14 },
    { label: 'screens.settings.lendDurations.threeWeeks', value: 21 },
    { label: 'screens.settings.lendDurations.fourWeeks', value: 28 }
]

export const BOOK_WEB_SHARE_OPTIONS = [
    { 
        type: 'twitter',
        name: 'Twitter',
        icon: 'logo-twitter',
        iconSize: 23
    },
    { 
        type: 'facebook',
        name: 'Facebook',
        icon: 'logo-facebook',
        iconSize: 26
    },
    { 
        type: 'email',
        name: 'E-mail',
        icon: 'md-mail',
        iconSize: 23
    }
]

export const COLUMN_NBR_RANGES_BOOK_ITEM = { 
    1: [0, 299],
    2: [300, 499],
    3: [500, 699],
    4: [700, 899],
    5: [900, 1099],
    6: [1100, 1299],
    7: [1300, 1499],
    8: [1500, 1699],
    9: [1700, 1899],
    10: [1900, 2099],
    11: [2100, 2299],
    12: [2300, 2499],
    13: [2500, 2699],
    14: [2700, 2899],
    15: [2900, 3099],
    16: [3100, 3299]
}

export const COLUMN_NBR_RANGES_GROUP_ITEM = { 
    1: [0, 799],
    2: [800, 1249],
    3: [1250, 1749],
    4: [1750, 2249],
    5: [2250, 2749]
}

export const COLUMN_NBR_RANGES_USER_ITEM = { 
    1: [0, 799],
    2: [800, 1249],
    3: [1250, 1749],
    4: [1750, 2249],
    5: [2250, 2749]
}

export const COLUMN_NBR_RANGES_LEND_ITEM = { 
    1: [0, 799],
    2: [800, 1249],
    3: [1250, 1749],
    4: [1750, 2249],
    5: [2250, 2749]
}

export const MODALS_TO_NOT_OPEN_ON_PAGE_LOAD = [
    'UpdateShelf', 
    'UpdateList', 
    'UpdateGroup', 
    'UpdateBook',
    'AddGroupCollection',
    'UpdateGroupCollection',
    'BooksInGroupCollection',
    'Lend', 
    'AddBookReview', 
    'InviteToJoinGroup', 
    'BooksInGroup', 
    'BookInLists',
    'Book',
    'User',
    'Group',
    'GroupBook',
    'GroupCollection',
    'Following',
    'Followers',
    'Shelf',
    'List',
    'BookProviderResult',
    'PrePaymentGroupProduct',
    'GroupProduct',
    'GroupProductPro',
    'PremiumGroupOnly',
    'PrePaymentAccountProduct',
    'AccountProduct',
    'PremiumAccountOnly',
    'UpdateShelfShareBooksGroup',
    'GroupShare',
    'AddUserKeyword',
    'UpdateUserKeyword'
];

export const MODALS_OPENABLE_BY_ACTION_URL_PARAM = {
    'BooksInGroup': {
        routeParams: ['groupId'],
        requirements: ['isMember'],
        context: 'group'
    },
    'UpdateGroup': {
        routeParams: ['groupId'],
        requirements: ['isAdmin'],
        context: 'group'
    },
    'InviteToJoinGroup': {
        routeParams: ['groupId'],
        requirements: ['isMember'],
        context: 'group'
    },
    'AddGroupCollection': {
        routeParams: ['groupId'],
        requirements: ['isAdmin', 'canAddCollections'],
        context: 'group'
    }, 
    'AddBookReview': {
        routeParams: ['bookId'],
        requirements: [],
        context: 'book'
    }
}

export const PUBLIC_ROUTE_NAMES = [
    'Book', 
    'Shelf',
    'List',
    'Group',
    'GroupBook', 
    'GroupCollection',
    'User',
    'SignIn',
    'SignUp',
    'ResetPassword',
    'Welcome'
]

export const CANONICAL_PATHS = {
    Book: {
        [`/${APP_ROOT_PATH}home/book/:bookId`]: `/${APP_ROOT_PATH}book/:bookId`,
        [`/${APP_ROOT_PATH}library/book/:bookId`]: `/${APP_ROOT_PATH}book/:bookId`,
        [`/${APP_ROOT_PATH}exchanges/book/:bookId`]: `/${APP_ROOT_PATH}book/:bookId`,
        [`/${APP_ROOT_PATH}groups/book/:bookId`]: `/${APP_ROOT_PATH}book/:bookId`,
        [`/${APP_ROOT_PATH}modal/book/:bookId`]: `/${APP_ROOT_PATH}book/:bookId`,
    },
    GroupBook: {
        [`/${APP_ROOT_PATH}groups/group/:groupSlug/:groupId/book/:bookId`]: `/${APP_ROOT_PATH}group/:groupSlug/:groupId/book/:bookId`,
        [`/${APP_ROOT_PATH}modal/group/:groupSlug/:groupId/book/:bookId`]: `/${APP_ROOT_PATH}group/:groupSlug/:groupId/book/:bookId`,
    },
    User: {
        [`/${APP_ROOT_PATH}home/user/:userId`]: `/${APP_ROOT_PATH}user/:userId`,
        [`/${APP_ROOT_PATH}library/user/:userId`]: `/${APP_ROOT_PATH}user/:userId`,
        [`/${APP_ROOT_PATH}exchanges/user/:userId`]: `/${APP_ROOT_PATH}user/:userId`,
        [`/${APP_ROOT_PATH}groups/user/:userId`]: `/${APP_ROOT_PATH}user/:userId`,
        [`/${APP_ROOT_PATH}modal/user/:userId`]: `/${APP_ROOT_PATH}user/:userId`,
    },
    Group: {
        [`/${APP_ROOT_PATH}home/group/:groupSlug/:groupId`]: `/${APP_ROOT_PATH}group/:groupSlug/:groupId`,
        [`/${APP_ROOT_PATH}groups/group/:groupSlug/:groupId`]: `/${APP_ROOT_PATH}group/:groupSlug/:groupId`,
        [`/${APP_ROOT_PATH}modal/group/:groupSlug/:groupId`]: `/${APP_ROOT_PATH}group/:groupSlug/:groupId`,
    },
    GroupCollection: {
        [`/${APP_ROOT_PATH}home/group/group/:groupSlug/:groupId/collection/:groupCollectionId`]: `/${APP_ROOT_PATH}group/:groupSlug/:groupId/collection/:groupCollectionId`,
        [`/${APP_ROOT_PATH}groups/group/:groupSlug/:groupId/collection/:groupCollectionId`]: `/${APP_ROOT_PATH}group/:groupSlug/:groupId/collection/:groupCollectionId`,
        [`/${APP_ROOT_PATH}modal/group/:groupSlug/:groupId/collection/:groupCollectionId`]: `/${APP_ROOT_PATH}group/:groupSlug/:groupId/collection/:groupCollectionId`
    },
    Shelf: {
        [`/${APP_ROOT_PATH}library/shelf/:shelfId`]: `/${APP_ROOT_PATH}shelf/:shelfId`,
        [`/${APP_ROOT_PATH}home/user/shelf/:shelfId`]: `/${APP_ROOT_PATH}shelf/:shelfId`,
        [`/${APP_ROOT_PATH}modal/user/shelf/:shelfId`]: `/${APP_ROOT_PATH}shelf/:shelfId`,
        [`/${APP_ROOT_PATH}groups/user/shelf/:shelfId`]: `/${APP_ROOT_PATH}shelf/:shelfId`
    },
    List: {
        [`/${APP_ROOT_PATH}library/list/:listId`]: `/${APP_ROOT_PATH}list/:listId`,
        [`/${APP_ROOT_PATH}home/user/list/:listId`]: `/${APP_ROOT_PATH}list/:listId`,
        [`/${APP_ROOT_PATH}modal/user/list/:listId`]: `/${APP_ROOT_PATH}list/:listId`,
        [`/${APP_ROOT_PATH}groups/user/list/:listId`]: `/${APP_ROOT_PATH}list/:listId`
    }
}

export const CANONICAL_LOGGED_PATHS = {
    Book: {
        [`/${APP_ROOT_PATH}book/:bookId`]: `/${APP_ROOT_PATH}home/book/:bookId`,
        [`/${APP_ROOT_PATH}modal/book/:bookId`]: `/${APP_ROOT_PATH}home/book/:bookId`,
    },
    User: {
        [`/${APP_ROOT_PATH}user/:userId`]: `/${APP_ROOT_PATH}home/user/:userId`,
        [`/${APP_ROOT_PATH}modal/user/:userId`]: `/${APP_ROOT_PATH}home/user/:userId`,
    },
    Group: {
        [`/${APP_ROOT_PATH}group/:groupSlug/:groupId`]: `/${APP_ROOT_PATH}groups/group/:groupSlug/:groupId`,
        [`/${APP_ROOT_PATH}modal/group/:groupSlug/:groupId`]: `/${APP_ROOT_PATH}groups/group/:groupSlug/:groupId`,
    },
    GroupBook: {
        [`/${APP_ROOT_PATH}modal/group/:groupSlug/:groupId/book/:bookId`]: `/${APP_ROOT_PATH}groups/group/:groupSlug/:groupId/book/:bookId`,
    },
    GroupCollection: {
        [`/${APP_ROOT_PATH}group/:groupSlug/:groupId/collection/:groupCollectionId`]: `/${APP_ROOT_PATH}groups/group/:groupSlug/:groupId/collection/:groupCollectionId`,
        [`/${APP_ROOT_PATH}modal/group/:groupSlug/:groupId/collection/:groupCollectionId`]: `/${APP_ROOT_PATH}groups/group/:groupSlug/:groupId/collection/:groupCollectionId`,
    },
    Shelf: {
        [`/${APP_ROOT_PATH}shelf/:shelfId`]: `/${APP_ROOT_PATH}home/user/shelf/:shelfId`,
        [`/${APP_ROOT_PATH}modal/user/shelf/:shelfId`]: `/${APP_ROOT_PATH}home/user/shelf/:shelfId`, 
    },
    List: {
        [`/${APP_ROOT_PATH}list/:listId`]: `/${APP_ROOT_PATH}home/user/list/:listId`,
        [`/${APP_ROOT_PATH}modal/user/list/:listId`]: `/${APP_ROOT_PATH}home/user/list/:listId`, 
    }
}

export const IMAGE_CACHE_FOLDER = `${FileSystem.cacheDirectory}`

export const SERVER_FORM_PARAMS_TO_YUP_FIELDS = {
    autoAddBooksToGroupId: 'shouldAutoAdd',
    availableOnSiteForGroupId: 'availableOnSite',
    availableForSelfServiceForGroupId: 'availableSelfService'
}

export const BOOK_FILTER_DEFAULT = {
    possessed: null,
    lending: null,
    readingStatus: null,
    rating: null,
    keywords: []
}

export const BOOK_FILTER_COUNT = {
    possessed: null,
    lending: null,
    readingStatus: [],
    rating: null,
    keywords: []
}

// Pill tab nav

export const PILL_TAB_HEIGHT = 34;
export const PILL_TAB_R_MARGIN = 8;
export const PILL_TAB_HOR_PADDING = 12;
export const PILL_TABS_VERT_SPACING = 15;
export const PILL_TABS_HEIGHT = PILL_TAB_HEIGHT + (PILL_TABS_VERT_SPACING*2);
export const PILL_TABS_SCROLL_HIDE_THRESHOLD = 30;