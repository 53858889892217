import React, { memo } from 'react';
import { View } from 'react-native';
import StarRating from 'react-native-star-rating-widget';

function StaticRating(props) {
    return (
        <View pointerEvents='box-only'>
            <StarRating 
                enableSwiping={false}
                onChange={() => {}}
                animationConfig={{ scale: 1 }}
                {...props} />
        </View>
    )
}

export default memo(StaticRating);